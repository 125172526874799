/* eslint-disable no-undef */
// jshint esversion: 9

const credentials = require("@/assets/credentials.json");
const BUILD = credentials.build;

const CONSTANTS = {
  MISC: { build: BUILD != "live" ? BUILD.charAt(0).toUpperCase() + BUILD.slice(1) : "" },
  API: credentials.endpoints[BUILD].api,
  AUTH: {
    LOGOUT: credentials.endpoints[BUILD].auth + `/logout`,
    BACKEND: credentials.endpoints[BUILD].auth + `/auth`,
    DISCORD: `https://discord.com/oauth2/authorize?client_id=${credentials.clientID}&redirect_uri=${credentials.endpoints[BUILD].auth}/return&response_type=code&scope=identify%20guilds`,
  },
  URLS: {
    DISCORD_INVITE: "https://discord.com/invite/hwrb46E/",
    BOT_INVITE:
      "https://discord.com/api/oauth2/authorize?client_id=352801398385016832&permissions=0&redirect_uri=https%3A%2F%2Fdynchan.net&scope=bot",
    TOPGG: "https://top.gg/bot/352801398385016832",
    GITHUB: "https://github.com/dynchan",
  },
  PERMISSIONS: {
    general: [
      {
        name: "Create Instant Invite",
        desc: "Create an instant invite for the voice or text channel",
        bit: "1",
      },
      // {
      //   name: "Kick Members",
      //   desc: "Kick members from the server",
      //   bit: "2",
      // },
      // {
      //   name: "Ban Members",
      //   desc: "Ban members from the server",
      //   bit: "4",
      // },
      // {
      //   name: "Administrator",
      //   desc: "Full access to all features and bypasses channel permission overwrites",
      //   bit: "8",
      // },
      {
        name: "Manage Channels",
        desc: "Manage the settings (name, bitrate, user limit, settings, etc.) for the voice or text channel",
        bit: "16",
      },
      // {
      //   name: "Manage Guild",
      //   desc: "Manage server settings and features",
      //   bit: "32",
      // },
      // {
      //   name: "View Audit Log",
      //   desc: "View the server's audit log",
      //   bit: "128",
      // },
      // {
      //   name: "View Guild Insights",
      //   desc: "View server insights and analytics",
      //   bit: "524288",
      // },
      // {
      //   name: "Change Nickname",
      //   desc: "Change your own nickname",
      //   bit: "67108864",
      // },
      // {
      //   name: "Manage Nicknames",
      //   desc: "Change nicknames of other members",
      //   bit: "134217728",
      // },
      {
        name: "Manage Roles",
        desc: "Manage roles and permissions",
        bit: "268435456",
      },
      // {
      //   name: "Manage Webhooks",
      //   desc: "Manage webhooks",
      //   bit: "536870912",
      // },
      // {
      //   name: "Manage Emojis and Stickers",
      //   desc: "Manage custom emojis and stickers for the server",
      //   bit: "1073741824",
      // },
      // {
      //   name: "Manage Events",
      //   desc: "Manage scheduled events in the server",
      //   bit: "8589934592",
      // },
      // {
      //   name: "Moderate Members",
      //   desc: "Timeout members and edit timeouts",
      //   bit: "1099511627776",
      // },
      // {
      //   name: "View Creator Monetization Analytics",
      //   desc: "View creator monetization analytics",
      //   bit: "2199023255552",
      // },
      {
        name: "View Channel",
        desc: "View Channel (this includes viewing voice channels and reading messages in text channels)",
        bit: "1024",
      },
    ],
    voice: [
      {
        name: "Connect",
        desc: "Connect to a voice channel",
        bit: "1048576",
      },
      {
        name: "Deafen Members",
        desc: "Deafen other members in the voice channel",
        bit: "8388608",
      },
      {
        name: "Move Members",
        desc: "Move members out of the voice channel",
        bit: "16777216",
      },
      {
        name: "Mute Members",
        desc: "Mute members within the voice channel",
        bit: "4194304",
      },
      {
        name: "Priority Speaker",
        desc: "Become priority speaker",
        bit: "256",
      },
      {
        name: "Speak",
        desc: "The ability to speak within the voice channel",
        bit: "2097152",
      },
      {
        name: "Stream & Screen Share",
        desc: "Stream & Screen Share",
        bit: "512",
      },
      {
        name: "Start Embedded Activities",
        desc: "Start Embedded Activities in voice channels",
        bit: "549755813888",
      },
      {
        name: "Use VAD",
        desc: "Use voice activation detection to avoid the need of push to talk",
        bit: "33554432",
      },
      {
        name: "Request to Speak",
        desc: "Request to speak in stage channels",
        bit: "4294967296",
      },
      {
        name: "Use Soundboard",
        desc: "Use the soundboard in voice channels",
        bit: "4398046511104",
      },
      {
        name: "Send Voice Messages",
        desc: "Send voice messages in voice channels",
        bit: "70368744177664",
      },
    ],
    text: [
      {
        name: "Add Reactions",
        desc: "Add reactions to messages within the text channel",
        bit: "64",
      },
      {
        name: "Attach Files",
        desc: "Attach files in messages within the text channel",
        bit: "32768",
      },
      {
        name: "Create/Use Private Threads",
        desc: "Create and use private threads for the text channel",
        bit: "68719476736",
      },
      {
        name: "Create/Use Public Threads",
        desc: "Create and use public threads for the text channel",
        bit: "34359738368",
      },
      {
        name: "Embed Links",
        desc: "Embed links in the text channel",
        bit: "16384",
      },
      {
        name: "Manage Messages",
        desc: "Manage messages within the text channel (such as deleting messages from other members)",
        bit: "8192",
      },
      {
        name: "Manage Threads",
        desc: "Manage threads within of the text channel",
        bit: "17179869184",
      },
      {
        name: "Mention Everyone",
        desc: "Mention @everyone in the text channel",
        bit: "131072",
      },
      {
        name: "Read Message History",
        desc: "Read messages that were sent before the user was able to see the text channel",
        bit: "65536",
      },
      {
        name: "Send Messages",
        desc: "Send messages to the text channel",
        bit: "2048",
      },
      {
        name: "Send Messages in Threads",
        desc: "Send messages in threads within the text channel",
        bit: "274877906944",
      },
      {
        name: "Send TTS Messages",
        desc: "Send text to speech messages within the text channel",
        bit: "4096",
      },
      {
        name: "Use Application Commands",
        desc: "Use /commands from bots in the text channel",
        bit: "2147483648",
      },
      {
        name: "Use External Emojis",
        desc: "Use emojis from other Discord servers within the text channel",
        bit: "262144",
      },
      {
        name: "Use External Stickers",
        desc: "Use stickers from other Discord servers within the text channel",
        bit: "137438953472",
      },
    ],
  },
};

module.exports = CONSTANTS;
