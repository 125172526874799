<template>
  <div id="app">
    <!-- /* ------------------------ */ -->
    <!-- /*          DIALOGS         */ -->
    <!-- /* ------------------------ */ -->
    <!-- /* -------- Tooltip ------- */ -->
    <md-snackbar :md-theme="theme" :md-active.sync="dialogs.tooltip" :md-duration="15000">
      <span @click="dialogs.tooltip = false">
        <!-- <strong>{{ tooltip.title }}: </strong> -->
        {{ tooltip.content }}
      </span>
      <md-button class="mdc-primary" @click="dialogs.tooltip = false"> Close </md-button>
    </md-snackbar>
    <!-- <md-dialog class="mdc-primary" :md-theme="theme" :md-active.sync="dialogs.tooltip">
      <md-dialog-title>{{ tooltip.title }}</md-dialog-title>
      <md-dialog-content>
        {{ tooltip.content }}
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="mdc-primary" @click="dialogs.tooltip = false">Okay</md-button>
      </md-dialog-actions>
    </md-dialog> -->
    <!-- /* - Import Configuration - */ -->
    <md-dialog
      id="importConfig"
      class="mdc-import"
      :md-theme="theme"
      :md-active.sync="dialogs.importConfig">
      <md-dialog-title>
        Import Configuration
        <span v-if="importConfigFile"> - </span>
        <span class="warning md-title" v-if="importConfigFile && !importConfigFileData">
          Could not load configuration file!
        </span>
        <span class="success md-title" v-if="importConfigFile && importConfigFileData">
          Configuration file loaded!
        </span></md-dialog-title
      >
      <md-dialog-content>
        Please select a previously exported configuration file.
        <md-field>
          <label>Configuration File</label>
          <md-file
            id="importConfigFile"
            @change="checkImportConfig()"
            v-model="importConfigFile"
            accept=".json,.yaml" />
        </md-field>
        <md-field>
          <label>Import Behavior</label>
          <md-select v-model="importConfigAsNew">
            <md-option v-if="currentConfig" value="false">
              Overwrite current Configuration
            </md-option>
            <md-option value="true">Create new Configuration</md-option>
          </md-select>
        </md-field>
        <md-field>
          <label>Import Data</label>
          <md-select md-dense class="mdc-primary" v-model="importConfigAllData" name="Test">
            <md-option value="1">All Data</md-option>
            <md-option value="0">Only selected Data</md-option>
          </md-select>
        </md-field>
        <span id="importConfigSelectDataLabel" v-if="importConfigAllData == '0'" class="md-title">
          Select Data:
        </span>
        <md-content
          v-if="importConfigAllData == '0'"
          id="importConfigPartSwitchContainer"
          class="md-scrollbar">
          <md-switch
            class="md-primary importConfigPartSwitch"
            v-for="(data, name) in this.importConfigParts"
            :key="name"
            v-model="importConfigParts[name].value">
            {{ data.name }}
          </md-switch>
        </md-content>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button @click="cancelImport()">Cancel</md-button>
        <md-button
          class="mdc-import"
          :disabled="!importConfigFileData || (this.selectConfigID == 0 && !this.importConfigAsNew)"
          @click="importConfig()">
          Import
        </md-button>
      </md-dialog-actions>
    </md-dialog>
    <!-- /* - Export Configuration - */ -->
    <md-dialog class="mdc-export" :md-theme="theme" :md-active.sync="dialogs.exportConfig">
      <md-dialog-title>Export Configuration</md-dialog-title>
      <md-dialog-content>
        <span class="md-subheading">Settings</span>
        <md-field>
          <label>File Type</label>
          <md-select md-dense class="md-primary" v-model="exportCNFlags.filetype">
            <md-option value="json">JSON</md-option>
            <md-option value="yaml">YAML</md-option>
          </md-select>
        </md-field>
        <md-field>
          <label>File Name Letter Case</label>
          <md-select v-model="exportCNFlags.case" @md-selected="validateExportConfigFileName()">
            <md-option value="lower">Lowercase</md-option>
            <!-- <md-option value="normal">Unchanged</md-option> -->
            <md-option value="upper">Uppercase</md-option>
          </md-select>
        </md-field>
        <span class="md-subheading">Insert Name Parts</span>
        <div id="exportChips">
          <md-chip
            md-clickable
            class="exportChips md-primary"
            v-for="chip of Object.entries(exportCNParts)"
            :key="chip[0]"
            @click="addExportFileNamePart(chip[0])">
            {{ chip[1] }}
          </md-chip>
        </div>
        <md-field id="exportConfigFileName">
          <label>File Name</label>
          <md-input @input="validateExportConfigFileName()" v-model="exportConfigFileName">
          </md-input>
          <span class="md-suffix"> .{{ exportCNFlags.filetype }} </span>
        </md-field>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button @click="dialogs.exportConfig = false">Cancel</md-button>
        <md-button
          class="mdc-export"
          :disabled="exportConfigFileName === ''"
          @click="exportConfig()"
          >Export</md-button
        >
      </md-dialog-actions>
    </md-dialog>
    <!-- /* ---- Show Wildcards ---- */ -->
    <md-dialog class="mdc-primary" :md-theme="theme" :md-active.sync="dialogs.showWildcards">
      <md-dialog-title>Channelname Wildcards</md-dialog-title>
      <md-dialog-content>
        Channelname wildcards can insert certain variable words into the name of the voice- and
        textchannels. Additional wildcards will be added in the future.
        <md-table>
          <md-table-row>
            <md-table-head></md-table-head>
            <md-table-head>Wildcard</md-table-head>
            <md-table-head>Description</md-table-head>
            <md-table-head>Example</md-table-head>
            <md-table-head>Result</md-table-head>
          </md-table-row>

          <md-table-row v-for="entry of getChannelNameWildcards()" :key="entry.wildcard">
            <md-table-cell>
              <md-button
                class="md-primary md-icon-button md-dense"
                @click="insertWildcard(entry.wildcard)">
                <md-icon>check_circle</md-icon>
              </md-button>
            </md-table-cell>
            <md-table-cell>{{ entry.wildcard }}</md-table-cell>
            <md-table-cell class="longText">{{ entry.description }}</md-table-cell>
            <md-table-cell>{{ entry.example }}</md-table-cell>
            <md-table-cell>{{ entry.result }}</md-table-cell>
          </md-table-row>

          <!-- <md-table-row>
            <md-table-cell>
              <md-button class="md-primary md-icon-button md-dense"><md-icon>check_circle</md-icon> </md-button>
            </md-table-cell>
            <md-table-cell>{username}</md-table-cell>
            <md-table-cell class="longText">The username of the user who created the channel.</md-table-cell>
            <md-table-cell>{username}'s channel</md-table-cell>
            <md-table-cell>{{ discord.user.username }}'s channel</md-table-cell>
          </md-table-row>

          <md-table-row>
            <md-table-cell>{displayname}</md-table-cell>
            <md-table-cell class="longText">
              The nickname on the current Discord server of the user who created the channel. If no nickname is set, the
              username is used instead.
            </md-table-cell>
            <md-table-cell>{displayname}'s channel</md-table-cell>
            <md-table-cell>cool-{{ discord.user.username }}'s channel</md-table-cell>
          </md-table-row> -->
        </md-table>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="mdc-primary" @click="dialogs.showWildcards = false">Okay</md-button>
      </md-dialog-actions>
    </md-dialog>
    <!-- /* -- Configuration Saved - */ -->
    <md-dialog class="mdc-success" :md-theme="theme" :md-active.sync="dialogs.configurationSaved">
      <md-dialog-title>Settings saved!</md-dialog-title>
      <md-dialog-content>
        All settings have been saved.<br />Please note that it takes up to
        <strong>5 seconds</strong> for the changes to be applied.
        <div v-if="saveTimerInterval">
          <md-progress-bar
            md-mode="buffer"
            :md-value="saveTimer"
            :md-buffer="getSavedBuffer()"></md-progress-bar>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="mdc-success" @click="dialogs.configurationSaved = false">
          Okay
        </md-button>
      </md-dialog-actions>
    </md-dialog>
    <!-- /* - Delete Configuration - */ -->
    <md-dialog
      class="mdc-accent"
      :md-theme="theme"
      v-if="currentConfig"
      :md-active.sync="dialogs.deleteConfiguration">
      <md-dialog-title>Really delete configuration?</md-dialog-title>
      <md-dialog-content>
        Do you really want to delete the configuration <strong>{{ currentConfig.name }}</strong
        >? <br />
        This action <span class="warning">cannot</span> be undone.
      </md-dialog-content>
      <md-dialog-actions>
        <md-button @click="dialogs.deleteConfiguration = false">Cancel</md-button>
        <md-button class="mdc-accent" @click="deleteConfiguration()">Delete</md-button>
      </md-dialog-actions>
    </md-dialog>
    <!-- /* ---- Reset Settings ---- */ -->
    <md-dialog class="mdc-warning" :md-theme="theme" :md-active.sync="dialogs.resetSettings">
      <md-dialog-title>Discard changes?</md-dialog-title>
      <md-dialog-content>Do you really want to discard all changes?</md-dialog-content>
      <md-dialog-actions>
        <md-button @click="dialogs.resetSettings = false">Cancel</md-button>
        <md-button class="mdc-warning" @click="resetSettings()">Discard</md-button>
      </md-dialog-actions>
    </md-dialog>
    <!-- /* ------------------------ */ -->
    <!-- /*            APP           */ -->
    <!-- /* ------------------------ */ -->
    <md-app md-waterfall md-mode="fixed" :md-theme="theme">
      <md-app-toolbar class="md-primary" v-if="!onlyHome">
        <md-button id="menu-button" class="md-icon-button" @click="menuVisible = !menuVisible">
          <md-icon>menu</md-icon>
        </md-button>
        <span class="md-title">DynChan {{ MISC.build }} WebPanel</span>
      </md-app-toolbar>
      <!-- ----------------------------------------------------------------------- -->
      <!--                                 DRAWER                                  -->
      <!-- ----------------------------------------------------------------------- -->
      <md-app-drawer
        class="md-scrollbar"
        :md-persistent="drawerPersistence()"
        :md-active.sync="menuVisible"
        md-swipeable="true"
        v-if="!onlyHome">
        <md-toolbar class="md-primary" id="drawerToolbar" md-elevation="0">
          <md-avatar v-if="loggedIn" class="md-avatar-icon discord-avatar">
            <img
              :src="`https://cdn.discordapp.com/avatars/${discord.user.id}/${discord.user.avatar}.png`" />
          </md-avatar>
          <!-- <marquee class="statusText" behavior="scroll" scrollDelay="500"> -->
          {{
            loggedIn
              ? `Welcome ${
                  discord.user.username.length != discord.user.username.substr(0, 10).length
                    ? discord.user.username.substr(0, 10) + ".."
                    : discord.user.username
                }!`
              : "Please log in via Discord."
          }}
          <!-- #${discord.user.discriminator} -->
          <!-- </marquee> -->
          <md-button v-if="!loggedIn" :href="AUTH.DISCORD" class="loginout login">
            Login
          </md-button>
          <md-button v-if="loggedIn" @click="logout()" class="loginout logout"> Logout </md-button>
        </md-toolbar>
        <md-list id="drawer">
          <md-subheader v-if="menuVisible">
            Welcome to DynChan {{ MISC.build }} WebPanel!
          </md-subheader>
          <md-list-item
            @click="showView = 'home'"
            :class="{ selectedListEntry: showView == 'home' }">
            <md-icon>home</md-icon>
            <span class="md-list-item-text md-primary">Home</span>
          </md-list-item>
          <md-list-item @click="switchTheme()">
            <md-icon>{{ theme == "default" ? "dark_mode" : "light_mode" }}</md-icon>
            <span class="md-list-item-text md-primary">Switch Theme</span>
          </md-list-item>
          <div v-if="loggedIn">
            <md-divider class="divider-margin"></md-divider>
            <md-subheader v-if="menuVisible">
              Configure DynChan {{ MISC.build }}
              <span
                class="tinyInfoText"
                @click="
                  openToolTip(
                    'Missing Server?',
                    'Admin permissions are required to change settings.'
                  )
                ">
                Missing server?
              </span>
            </md-subheader>
            <md-list-item
              :class="{
                selectedListEntry:
                  showView == 'webpanel' && currentGuild && currentGuild.discord.id == guild.id,
              }"
              v-for="guild in discord.dcguilds"
              v-bind:key="guild.id"
              @click="selectDiscordServer(guild.id, true)">
              <md-avatar v-if="!guild.icon">
                <img src="@/assets/discord.svg" />
              </md-avatar>
              <md-avatar v-if="guild.icon">
                <img
                  :src="`https://cdn.discordapp.com/icons/${guild.id}/${
                    guild.icon
                  }.${checkAnimatedIcon(guild.features)}`" />
              </md-avatar>
              <span class="md-list-item-text">{{ guild.name }}</span>
            </md-list-item>
            <md-list-item v-if="discord.dcguilds.length == 0">
              <div class="md-list-item-text">
                <span> No Discord server available to configurate. </span>
                <span v-if="discord.guilds.length != 0">Use the list below to invite DynChan.</span>
                <span v-else>Create one or ask for admin permissions.</span>
              </div>
            </md-list-item>
            <div v-if="discord.guilds.length != 0">
              <md-divider class="divider-margin"></md-divider>
              <md-subheader v-if="menuVisible"> Invite DynChan to your Server </md-subheader>
              <md-list-item
                v-for="guild in discord.guilds"
                v-bind:key="guild.id"
                :href="URLS.BOT_INVITE + `&guild_id=${guild.id}`"
                target="_blank">
                <md-avatar v-if="!guild.icon">
                  <img src="@/assets/discord.svg" />
                </md-avatar>
                <md-avatar v-if="guild.icon" class="md-avatar-icon discord-avatar">
                  <img
                    :src="`https://cdn.discordapp.com/icons/${guild.id}/${
                      guild.icon
                    }.${checkAnimatedIcon(guild.features)}`" />
                </md-avatar>
                <span class="md-list-item-text">{{ guild.name }}</span>
              </md-list-item>
            </div>
          </div>

          <md-divider class="divider-margin"></md-divider>
          <md-subheader v-if="menuVisible"> Links </md-subheader>
          <md-list-item :href="URLS.BOT_INVITE" target="_blank">
            <md-avatar class="md-avatar-icon discord-avatar">
              <img src="@/assets/dynchan.png" alt="Top.gg" />
            </md-avatar>
            <span class="md-list-item-text">Invite DynChan</span>
          </md-list-item>
          <md-list-item :href="URLS.DISCORD_INVITE" target="_blank">
            <md-avatar class="md-avatar-icon discord-avatar">
              <img src="@/assets/dynchan.png" alt="Discord Invite" />
            </md-avatar>
            <span class="md-list-item-text">Join the Community</span>
          </md-list-item>
          <md-list-item :href="URLS.TOPGG" target="_blank">
            <md-avatar class="md-avatar-icon discord-avatar">
              <img src="@/assets/topgg.svg" alt="Top.gg" />
            </md-avatar>
            <span class="md-list-item-text">Vote on Top.gg!</span>
          </md-list-item>
          <md-list-item :href="URLS.GITHUB" target="_blank">
            <md-avatar class="md-avatar-icon discord-avatar">
              <img src="@/assets/github.png" alt="Github" />
            </md-avatar>
            <span class="md-list-item-text">GitHub</span>
          </md-list-item>
          <md-divider class="divider-margin"></md-divider>
          <md-subheader v-if="menuVisible"> Donations </md-subheader>
          <md-list-item href="https://www.paypal.com/paypalme/zlyfer" target="_blank">
            <md-avatar class="md-avatar-icon discord-avatar">
              <img src="@/assets/paypal.svg" alt="Top.gg" />
            </md-avatar>
            <span class="md-list-item-text">Buy me an Energy Drink</span>
          </md-list-item>
          <md-list-item href="https://www.patreon.com/zlyfer" target="_blank">
            <md-avatar class="md-avatar-icon discord-avatar">
              <img src="@/assets/patreon_logo.png" alt="Top.gg" />
            </md-avatar>
            <span class="md-list-item-text">Become a Patreon</span>
          </md-list-item>
          <md-divider class="divider-margin"></md-divider>
          <md-subheader v-if="menuVisible"> Other </md-subheader>
          <md-list-item
            @click="showView = 'imprint'"
            :class="{ selectedListEntry: showView == 'imprint' }">
            <md-icon>article</md-icon>
            <span class="md-list-item-text md-primary">Imprint</span>
          </md-list-item>
        </md-list>
      </md-app-drawer>
      <!-- ----------------------------------------------------------------------- -->
      <!--                                 CONTENT                                 -->
      <!-- ----------------------------------------------------------------------- -->
      <md-app-content v-if="!loading" :class="{ expanded: showView == 'home' }">
        <!-- /* ---------------------------- */ -->
        <!-- /*          Speed Dials         */ -->
        <!-- /* ---------------------------- */ -->
        <md-speed-dial
          v-if="showView == 'webpanel' && loggedIn && currentGuild && useFAB"
          class="md-bottom-right md-primary"
          md-direction="top"
          md-event="click"
          style="position: absolute !important; z-index: 100000 !important">
          <md-speed-dial-target class="mdc-primary">
            <md-icon class="md-morph-initial">build</md-icon>
            <md-icon class="md-morph-final">close</md-icon>
          </md-speed-dial-target>
          <md-speed-dial-content>
            <md-button class="mdc-primary md-icon-button" @click="addConfiguration()">
              <md-icon>add</md-icon>
            </md-button>
            <md-button
              :disabled="!checkChanged()"
              class="mdc-success md-icon-button"
              @click="saveAll()">
              <md-icon>save</md-icon>
            </md-button>
            <md-button
              :disabled="!checkChanged()"
              class="mdc-warning md-icon-button"
              @click="dialogs.resetSettings = true">
              <md-icon>settings_backup_restore</md-icon>
            </md-button>
            <md-button
              :disabled="!currentConfig"
              class="mdc-accent md-icon-button"
              @click="dialogs.deleteConfiguration = true">
              <md-icon>delete</md-icon>
            </md-button>
            <md-button class="mdc-import md-icon-button" @click="dialogs.importConfig = true">
              <md-icon>file_upload</md-icon>
            </md-button>
            <md-button
              :disabled="!currentConfig"
              class="mdc-export md-icon-button"
              :class="{ '//md-raised': currentConfig }"
              @click="openExportConfigDialog()">
              <md-icon>file_download</md-icon>
            </md-button>
          </md-speed-dial-content>
        </md-speed-dial>
        <router-view v-if="showView == 'home'" name="home"></router-view>
        <router-view v-if="showView == 'imprint'" name="imprint"></router-view>
        <md-card
          class="contentCard fullWidth"
          v-if="loggedIn && !currentGuild && showView == 'webpanel'">
          <md-card-content>
            <md-empty-state
              md-icon="settings"
              md-label="Please select a Discord server"
              md-description="Select a Discord server on the left sidebar to start tinkering.">
            </md-empty-state>
          </md-card-content>
        </md-card>
        <md-card class="contentCard fullWidth" v-if="!loggedIn && showView == 'webpanel'">
          <md-card-content>
            <md-empty-state
              md-icon="login"
              md-label="Please login via Discord"
              md-description="You need to login via Discord to get things started.">
            </md-empty-state>
          </md-card-content>
        </md-card>
        <md-card
          v-if="currentGuild && showView == 'webpanel'"
          class="contentCard"
          :class="{ fullWidth: !currentConfig }">
          <md-card-header>
            <div class="md-title">
              <md-avatar
                v-if="!currentGuild.discord.icon"
                class="md-avatar-icon md-large discord-avatar">
                <img src="@/assets/discord.svg" />
              </md-avatar>
              <md-avatar
                v-if="currentGuild.discord.icon"
                class="md-avatar-icon md-large discord-avatar">
                <img
                  :src="`https://cdn.discordapp.com/icons/${currentGuild.discord.id}/${
                    currentGuild.discord.icon
                  }.${checkAnimatedIcon(currentGuild.discord.features)}`" />
              </md-avatar>
              {{ currentGuild.discord.name }}
            </div>
            <div class="md-subhead">Global Settings</div>
          </md-card-header>
          <md-card-content>
            <!-- ---------------------------- Bot Settings ----------------------------- -->
            <md-card-content>
              <md-list>
                <div class="switchContainer">
                  <md-switch v-model="currentGuild.dynchan.botSettings.enabled" class="md-primary">
                    Enable DynChan
                  </md-switch>
                  <md-button
                    class="md-icon-button tooltip switchTooltip"
                    @click="
                      openToolTip(
                        'Enable DynChan',
                        'Enable/Disable DynChan on your Discord server.'
                      )
                    ">
                    <md-icon>info</md-icon>
                  </md-button>
                </div>
                <!-- <md-field :class="{ warning: currentGuild.dynchan.botSettings.prefix == '' }">
                  <label>Bot Prefix</label>
                  <md-input v-model="currentGuild.dynchan.botSettings.prefix"></md-input>
                  <md-button @click="currentGuild.dynchan.botSettings.prefix = 'dynchan'" class="md-icon-button inputActionButton">
                    <md-icon>undo</md-icon>
                  </md-button>
                  <md-button class="md-icon-button tooltip" @click="openToolTip('Bot Prefix', 'The keyword to use commands.')">
                    <md-icon>info</md-icon>
                  </md-button>
                </md-field> -->
                <md-field>
                  <label>Select a configuration</label>
                  <md-select
                    md-dense
                    v-model="selectConfigID"
                    :disabled="currentGuild.dynchan.configurations.length == 0">
                    <md-optgroup label="DynChan Configurations">
                      <md-option
                        v-if="currentGuild.dynchan.configurations.length == 0"
                        disabled
                        value="0">
                        No Configuration Available
                      </md-option>
                      <md-option
                        v-for="config in currentGuild.configPickerArray"
                        :key="config.configid"
                        :value="config.configid">
                        {{ config.name }}
                      </md-option>
                    </md-optgroup>
                  </md-select>
                  <md-button
                    class="md-icon-button tooltip"
                    @click="
                      openToolTip('Select a Configuration', 'Select a configuration to edit it.')
                    ">
                    <md-icon>info</md-icon>
                  </md-button>
                </md-field>
              </md-list>
            </md-card-content>
          </md-card-content>
          <md-card-actions id="botSettings" md-alignment="space-between">
            <div v-if="!useFAB">
              <md-button class="mdc-primary mainButtons" @click="addConfiguration()">
                <md-icon>add</md-icon>
                New Configuration
              </md-button>
            </div>
            <div v-if="!useFAB">
              <md-button
                :disabled="!checkChanged()"
                class="mdc-success mainButtons"
                @click="saveAll()">
                <md-icon>save</md-icon>
                Save all changes
              </md-button>
            </div>
            <div v-if="!useFAB">
              <md-button
                :disabled="!checkChanged()"
                class="mdc-warning mainButtons"
                @click="dialogs.resetSettings = true">
                <md-icon>settings_backup_restore</md-icon>
                Discard all changes
              </md-button>
            </div>
            <div v-if="!useFAB">
              <md-button
                :disabled="!currentConfig"
                class="mdc-accent mainButtons"
                @click="dialogs.deleteConfiguration = true">
                <md-icon>delete</md-icon>
                Delete configuration
              </md-button>
            </div>
            <div v-if="!useFAB">
              <md-button class="mdc-import mainButtons" @click="dialogs.importConfig = true">
                <md-icon>file_upload</md-icon>
                Import Configuration
              </md-button>
            </div>
            <div v-if="!useFAB">
              <md-button
                :disabled="!currentConfig"
                class="mdc-export mainButtons"
                @click="openExportConfigDialog()">
                <md-icon>file_download</md-icon>
                Export Configuration
              </md-button>
            </div>
            <div v-if="saveTimerInterval" style="padding-top: 10px">
              <label>All settings saved! </label>
              <md-progress-bar
                md-mode="buffer"
                :md-value="saveTimer"
                :md-buffer="getSavedBuffer()"></md-progress-bar>
            </div>
          </md-card-actions>
        </md-card>
        <div v-if="currentConfig && showView == 'webpanel'">
          <!-- -------------------------- General Settings --------------------------- -->
          <md-card class="contentCard">
            <md-card-header>
              <div class="md-title">General Settings</div>
              <div class="md-subhead">Basic Settings</div>
            </md-card-header>
            <md-card-content>
              <md-list>
                <md-field>
                  <label>Configuration Name</label>
                  <md-input v-model="currentConfig.name"></md-input>
                  <md-button
                    class="md-icon-button tooltip"
                    @click="
                      openToolTip(
                        'Configuration Name',
                        'The name of this configuration so you can identify it.'
                      )
                    ">
                    <md-icon>info</md-icon>
                  </md-button>
                </md-field>
                <!-- /* -- CONFIGURATION COLOR - */ -->
                <!-- <md-field>
                  <md-tooltip md-direction="top">The color of this configuration</md-tooltip>
                  <label>Color:</label>
                  <md-input @input="checkHex($event)" :value="currentConfig.color"> </md-input>
                </md-field> -->
                <div class="switchContainer">
                  <md-switch v-model="currentConfig.enabled" class="md-primary">
                    Enable Configuration
                  </md-switch>
                  <md-button
                    class="md-icon-button tooltip switchTooltip"
                    @click="
                      openToolTip('Enable Configuration', 'Enable/Disable this configuration.')
                    ">
                    <md-icon>info</md-icon>
                  </md-button>
                </div>
                <md-field :class="{ warning: !currentConfig.triggerchannel }">
                  <label>Select a Trigger Channel</label>
                  <md-select md-dense v-model="currentConfig.triggerchannel">
                    <md-option disabled value=""> No Voice Channel Selected </md-option>
                    <md-optgroup label="Voice Channels">
                      <md-option
                        v-for="channel in currentGuild.dynchan.channels.voice"
                        :key="channel.id"
                        :value="channel.id">
                        {{ channel.name }}
                      </md-option>
                    </md-optgroup>
                  </md-select>
                  <md-button
                    class="md-icon-button tooltip"
                    @click="
                      openToolTip(
                        'Trigger Channel',
                        'The channel you have to join to create your own dynamic channel.'
                      )
                    ">
                    <md-icon>info</md-icon>
                  </md-button>
                </md-field>
                <div class="switchContainer">
                  <md-switch v-model="currentConfig.inherit" class="md-primary">
                    Inherit Ownership
                  </md-switch>
                  <md-button
                    class="md-icon-button tooltip switchTooltip"
                    @click="
                      openToolTip(
                        'Inherit Ownership',
                        'Upon leave, transfer channel \'ownership\' to the second user who joined.'
                      )
                    ">
                    <md-icon>info</md-icon>
                  </md-button>
                </div>
                <div class="switchContainer">
                  <md-switch v-model="currentConfig.createtext" class="md-primary">
                    Create Text Channel
                  </md-switch>
                  <md-button
                    class="md-icon-button tooltip switchTooltip"
                    @click="
                      openToolTip('Create Text Channel', 'Also create a dynamic text channel.')
                    ">
                    <md-icon>info</md-icon>
                  </md-button>
                </div>
                <md-field class="slider">
                  <label>
                    Activation Delay:
                    {{ customSliderConfig.delay.tooltipFormatter(currentConfig.delay) }}
                  </label>
                  <vue-slider
                    ref="slider"
                    v-model="currentConfig.delay"
                    v-bind="{ ...sliderConfig, ...customSliderConfig.delay }">
                  </vue-slider>
                  <md-button
                    class="md-icon-button tooltip sliderTooltip"
                    @click="
                      openToolTip('Activation Delay', 'Time in seconds before the bot triggers.')
                    ">
                    <md-icon>info</md-icon>
                  </md-button>
                </md-field>
              </md-list>
            </md-card-content>
          </md-card>
          <!-- --------------------------- Voice Settings ---------------------------- -->
          <md-card class="contentCard">
            <md-card-header>
              <div class="md-title">Voice Settings</div>
              <div class="md-subhead">Configure the dynamic voice channels</div>
            </md-card-header>
            <md-card-content>
              <md-list>
                <md-field :class="{ warning: currentConfig.vname == '' }">
                  <label> Voice Channel Name </label>
                  <md-input v-model="currentConfig.vname"> </md-input>
                  <md-button
                    @click="openDialogShowWildcards('vname')"
                    class="md-icon-button inputActionButton">
                    <md-icon class="md-primary">lightbulb</md-icon>
                  </md-button>
                  <md-button
                    class="md-icon-button tooltip"
                    @click="openToolTip('Voice Channel Name', 'The name of the voice channel.')">
                    <md-icon>info</md-icon>
                  </md-button>
                </md-field>
                <md-field class="margin-0">
                  <label>Select a Channel Category</label>
                  <md-select md-dense v-model="currentConfig.vcategory">
                    <md-option value=""> No Category </md-option>
                    <md-optgroup label="Channel Category">
                      <md-option
                        v-for="channel in currentGuild.dynchan.channels.category"
                        :key="channel.id"
                        :value="channel.id">
                        {{ channel.name }}
                      </md-option>
                    </md-optgroup>
                  </md-select>
                  <md-button
                    class="md-icon-button tooltip"
                    @click="
                      openToolTip(
                        'Channel Category',
                        'Set a category where the voice channel should be created in'
                      )
                    ">
                    <md-icon>info</md-icon>
                  </md-button>
                </md-field>

                <div class="switchContainer">
                  <md-switch
                    :disabled="currentConfig.vcategory == ''"
                    v-model="currentConfig.vlock"
                    class="md-primary">
                    Inherit Permissions
                  </md-switch>
                  <md-button
                    class="md-icon-button tooltip switchTooltip"
                    @click="
                      openToolTip(
                        'Inherit Permissions from Category',
                        'Inherit permissions from the parent category channel.'
                      )
                    ">
                    <md-icon>info</md-icon>
                  </md-button>
                </div>
                <md-field class="slider">
                  <label>
                    Userlimit:
                    {{ customSliderConfig.userlimit.tooltipFormatter(currentConfig.vuserlimit) }}
                  </label>
                  <vue-slider
                    ref="slider"
                    v-model="currentConfig.vuserlimit"
                    v-bind="{ ...sliderConfig, ...customSliderConfig.userlimit }">
                  </vue-slider>
                  <md-button
                    class="md-icon-button tooltip sliderTooltip"
                    @click="
                      openToolTip(
                        'Userlimit',
                        'Maximum amount of users who can join the voice channel.'
                      )
                    ">
                    <md-icon>info</md-icon>
                  </md-button>
                </md-field>
                <md-field class="slider">
                  <label>
                    Bitrate:
                    {{ customSliderConfig.bitrate.tooltipFormatter(currentConfig.vbitrate) }}
                  </label>
                  <vue-slider
                    ref="slider"
                    v-model="currentConfig.vbitrate"
                    v-bind="{ ...sliderConfig, ...customSliderConfig.bitrate }">
                  </vue-slider>
                  <md-button
                    class="md-icon-button tooltip sliderTooltip"
                    @click="
                      openToolTip(
                        'Bitrate',
                        'A value above the current boost tier allows will default back to the highest allowed.'
                      )
                    ">
                    <md-icon>info</md-icon>
                  </md-button>
                </md-field>
              </md-list>
            </md-card-content>
          </md-card>
          <!-- ---------------------------- Text Settings ---------------------------- -->
          <md-card class="contentCard" v-if="currentConfig.createtext">
            <md-card-header>
              <div class="md-title">Text Settings</div>
              <div class="md-subhead">Configure the dynamic text channels</div>
            </md-card-header>
            <md-card-content>
              <md-list>
                <md-field :class="{ warning: currentConfig.tname == '' }">
                  <label> Text Channel Name </label>
                  <md-input v-model="currentConfig.tname"></md-input>
                  <md-button
                    @click="openDialogShowWildcards('tname')"
                    class="md-icon-button inputActionButton">
                    <md-icon class="md-primary">lightbulb</md-icon>
                  </md-button>
                  <md-button
                    class="md-icon-button tooltip"
                    @click="openToolTip('Text Channel Name', 'The name of the text channel.')">
                    <md-icon>info</md-icon>
                  </md-button>
                </md-field>
                <md-field class="margin-0">
                  <label>Select a Channel Category</label>
                  <md-select md-dense v-model="currentConfig.tcategory">
                    <md-option value=""> No Category </md-option>
                    <md-optgroup label="Channel Category">
                      <md-option
                        v-for="channel in currentGuild.dynchan.channels.category"
                        :key="channel.id"
                        :value="channel.id">
                        {{ channel.name }}
                      </md-option>
                    </md-optgroup>
                  </md-select>
                  <md-button
                    class="md-icon-button tooltip"
                    @click="
                      openToolTip(
                        'Channel Category',
                        'Set a category where the text channel should be created in'
                      )
                    ">
                    <md-icon>info</md-icon>
                  </md-button>
                </md-field>
                <div class="switchContainer">
                  <md-switch
                    v-model="currentConfig.tlock"
                    class="md-primary"
                    :disabled="currentConfig.isolate || currentConfig.tcategory == ''">
                    Inherit Permissions
                  </md-switch>
                  <md-button
                    class="md-icon-button tooltip switchTooltip"
                    @click="
                      openToolTip(
                        'Inherit Permissions from Category',
                        'Inherit all permissions from the parent category channel. Currently conflicts with \'Isolate Channels\'.'
                      )
                    ">
                    <md-icon>info</md-icon>
                  </md-button>
                </div>
                <md-field>
                  <label>Text Channel Topic</label>
                  <md-input v-model="currentConfig.ttopic"></md-input>
                  <md-button
                    class="md-icon-button tooltip"
                    @click="openToolTip('Text Channel Topic', 'The topic of the text channel.')">
                    <md-icon>info</md-icon>
                  </md-button>
                </md-field>
                <div class="switchContainer">
                  <md-switch
                    v-model="currentConfig.isolate"
                    class="md-primary"
                    :disabled="currentConfig.tlock">
                    Isolate Text Channel
                  </md-switch>
                  <md-button
                    class="md-icon-button tooltip switchTooltip"
                    @click="
                      openToolTip(
                        'Isolate Text Channel',
                        'Text channels only visible for voice channel members.'
                      )
                    ">
                    <md-icon>info</md-icon>
                  </md-button>
                </div>
                <div class="switchContainer">
                  <md-switch v-model="currentConfig.tnsfw" class="md-primary">
                    Flag as NSFW
                  </md-switch>
                  <md-button
                    class="md-icon-button tooltip switchTooltip"
                    @click="
                      openToolTip(
                        'Flag as NSFW',
                        'Flag the text channel as not safe for work (NSFW).'
                      )
                    ">
                    <md-icon>info</md-icon>
                  </md-button>
                </div>
              </md-list>
            </md-card-content>
          </md-card>
          <!-- ----------------------------- Permissions ----------------------------- -->
          <md-card class="contentCard fullWidth" id="permissionsCard">
            <md-card-header>
              <div class="md-title">Permissions</div>
              <div class="md-subhead">Configure automatic permission assignments</div>
            </md-card-header>
            <md-card-content>
              <md-field id="permissionsForSelect">
                <label>Permission for?</label>
                <md-select md-dense v-model="activePermissionTab">
                  <md-option value="powner">Owner</md-option>
                  <md-option value="pother">Other</md-option>
                </md-select>
                <md-button
                  class="md-icon-button tooltip"
                  @click="
                    openToolTip(
                      'Permission for?',
                      'Owner = The user who created the channel | Other = All other who joined after'
                    )
                  ">
                  <md-icon>info</md-icon>
                </md-button>
              </md-field>
              <md-tabs class="md-transparent" md-alignment="fixed" md-active-tab="tab-general">
                <md-tab
                  v-bind:id="`tab-${key}`"
                  v-for="(cat, key) in PERMISSIONS"
                  :key="key"
                  :md-label="key">
                  <div
                    class="switchContainer permissionSwitch"
                    v-for="perm in PERMISSIONS[key]"
                    :key="perm">
                    <md-switch
                      :value="perm.bit"
                      v-model="activatedPermissions[activePermissionTab]"
                      @change="togglePermission(activePermissionTab, perm.bit)"
                      class="md-primary permissionSwitch"
                      :disabled="perm.disabled">
                      {{ perm.name }}
                    </md-switch>
                    <md-button
                      class="md-icon-button tooltip switchTooltip"
                      @click="
                        openToolTip(
                          perm.disabled ? 'Work in Progress!' : perm.name,
                          perm.disabled
                            ? 'This option is not implemented yet. (I am working on it!)'
                            : perm.desc
                        )
                      ">
                      <md-icon>info</md-icon>
                    </md-button>
                  </div>
                </md-tab>
              </md-tabs>
            </md-card-content>
          </md-card>
        </div>
        <!-- DEBUGGING -->
        <!-- <br />
        <br />
        <span v-if="activatedPermissions">{{ activatedPermissions }}</span>
        <br />
        <br />
        <span v-if="currentConfig">{{ currentConfig }}</span>
        <br />
        <br />
        <span v-if="discord">{{ discord }}</span>
        <br />
        <br />
        <span v-if="currentGuild">{{ currentGuild }}</span> -->
      </md-app-content>
    </md-app>
  </div>
</template>

<script>
const axios = require("axios");
const YAML = require("yaml");
const CONSTANTS = require("@/constants.js");
/* global BigInt */

export default {
  name: "app",
  components: {},
  data() {
    return {
      // ID:
      id: null,
      // CONSTANTS:
      MISC: CONSTANTS.MISC,
      API: CONSTANTS.API,
      AUTH: CONSTANTS.AUTH,
      URLS: CONSTANTS.URLS,
      PERMISSIONS: CONSTANTS.PERMISSIONS,
      // Permissions array:
      activatedPermissions: {
        powner: [],
        pother: [],
      },
      // Active permission tab:
      activePermissionTab: "powner",
      // View flag:
      showView: "webpanel",
      // OnlyHome flag:
      onlyHome: false,
      // Use floating action buttons:
      useFAB: false,
      // Menu visibility flag:
      menuVisible: true,
      // Dialogs visibility flag:
      dialogs: {
        tooltip: false,
        importConfig: false,
        exportConfig: false,
        showWildcards: false,
        configurationSaved: false,
        deleteConfiguration: false,
        resetSettings: false,
        switchAndDiscard: false,
      },
      // Insert-wildcard-button config-index target:
      showWildcardsInsertIndex: null,
      // Tooltip Dialog Data:
      tooltip: {
        title: "",
        content: "",
      },
      // Loading indicator:
      loading: false,
      // Logged in flag:
      loggedIn: false,
      // Not logged in flag for empty state:
      notLoggedIn: false,
      // Theme flag:
      theme: "default",
      // Discord data:
      discord: {
        // User data:
        user: {},
        // All guilds with Admin or higher perms:
        guilds: [],
        // Only guilds, DC is on:
        dcguilds: [],
      },
      // Currently selected guild:
      currentGuild: null,
      // Currently selected configuration:
      currentConfig: null,
      // Watcher Variable for selecting a configuration:
      selectConfigID: null,
      // 5 Seconds timer for saving the configurations:
      saveTimer: 0,
      // Interval variable for the save timer:
      saveTimerInterval: null,
      // Import configuration file:
      importConfigFile: null,
      // Import configuration data:
      importConfigFileData: null,
      // Import as new configuration:
      importConfigAsNew: true,
      // Import all configuration data:
      importConfigAllData: "1",
      // Import configuration properties:
      importConfigParts: {
        name: { name: "Name", value: true },
        enabled: { name: "Enabled", value: true },
        // color: {name: "", value: true},
        triggerchannel: { name: "Trigger Channel", value: true },
        inherit: { name: "Inherit Ownership", value: true },
        createtext: { name: "Create Text Channel", value: true },
        delay: { name: "Activation Delay", value: true },
        isolate: { name: "Isolate Text Channel", value: true },
        powner: { name: "Owner Permissions", value: true },
        pother: { name: "Other Permissions", value: true },
        vcategory: { name: "Voice Category", value: true },
        vlock: { name: "Inherit Permissions Voice", value: true },
        vname: { name: "Voice Channel Name", value: true },
        vuserlimit: { name: "Userlimit", value: true },
        vbitrate: { name: "Bitrate", value: true },
        tcategory: { name: "Text Category", value: true },
        tlock: { name: "Inherit Permissions Text", value: true },
        tname: { name: "Text Channel Name", value: true },
        tnsfw: { name: "Flag as NSFW", value: true },
        ttopic: { name: "Text Channel Topic", value: true },
      },
      // Export configuration file name:
      exportConfigFileName: "",
      // Name parts for export config file name:
      exportCNParts: {
        dynchanName: "Botname",
        userName: "Username",
        userID: "User ID",
        guildName: "Servername",
        guildID: "Server ID",
        configName: "Configurationname",
      },
      // Name flags for export config file name:
      exportCNFlags: {
        case: "lower", // lower, upper, ~~normal~~
        filetype: "json",
      },
      // Slider Options:
      sliderConfig: {
        contained: true,
        dotSize: 22,
        dotStyle: { "background-color": "#448AFF", border: "none" },
        dragOnClick: true,
        duration: 1.5,
        height: 16,
        labelActiveStyle: { color: "#448AFF" },
        labelStyle: { "font-size": "11px", transition: "color 0.3s ease" },
        lazy: true,
        process: true,
        processStyle: { "background-color": "#B8D2FF" },
        railStyle: { "background-color": "#c9c9c9" },
        stepActiveStyle: { "background-color": "#448AFF", "box-shadow": "none" },
        stepStyle: { "background-color": "#A3A3A3", "box-shadow": "none" },
        tooltip: "active",
        tooltipPlacement: "top",
        width: "10000px",
      },
      customSliderConfig: {
        delay: {
          marks: {
            0: "No Delay",
            60: "1 Minute",
            120: "2 Minutes",
            180: "3 Minutes",
            240: "4 Minutes",
            300: "5 Minutes",
          },
          interval: 1,
          max: 300,
          min: 0,
          tooltipFormatter: (val) => {
            if (val == 0) return "No Delay";
            let minutes = Math.floor(val / 60);
            let seconds = val % 60;
            let minutesString = `Minute${minutes == 1 ? "" : "s"}`;
            let secondsString = `Second${seconds == 1 ? "" : "s"}`;
            let minutesConcat = `${minutes} ${minutesString} `;
            let secondsConcat = `${seconds} ${secondsString}`;
            return `${minutes != 0 ? minutesConcat : ""}${seconds != 0 ? secondsConcat : ""}`;
          },
        },
        bitrate: {
          marks: {
            8000: "8 kbps",
            64000: "Default",
            96000: "96 kbps",
            128000: "Tier 1",
            256000: "Tier 2",
            384000: "Tier 3",
          },
          interval: 1000,
          min: 8000,
          max: 384000,
          tooltipFormatter: (val) => {
            return `${val / 1000} kbps`;
          },
        },
        userlimit: {
          marks: {
            0: "Unlimited",
            50: "50 Users",
            99: "99 Users",
          },
          interval: 1,
          max: 99,
          min: 0,
          tooltipFormatter: (val) => {
            if (val == 0) return "Unlimited";
            else return `${val} User${val != 1 ? "s" : ""}`;
          },
        },
      },
    };
  },
  watch: {
    currentConfig(newVal) {
      if (newVal == null) this.importConfigAsNew = true;
    },
    selectConfigID(newVal) {
      if (this.currentGuild && newVal != 0) this.selectConfiguration(newVal);
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.onResize();
    if (this.$route.query.onlyhome) {
      this.menuVisible = false;
      this.showView = "home";
      this.onlyHome = true;
      return;
    }
    if (this.$cookies.isKey("theme")) this.theme = this.$cookies.get("theme");
    this.id = this.$route.query.id || this.$cookies.get("id");
    if (this.id !== null) {
      axios.post(this.AUTH.BACKEND, { id: this.id }).then((data) => {
        this.$router.push("/").catch(() => {});
        if (data.data.code == 0) {
          this.$cookies.set("id", this.id, 604800 * 52);
          let guilds = data.data.data.guilds;
          this.discord.user = data.data.data.user;
          let filteredGuilds = guilds.filter(
            (g) => g.owner || this.checkPermission(g.permissions, 0x8)
          );
          this.dynchanGetGuilds(filteredGuilds).then((dynchanGuilds) => {
            guilds.forEach((guild) => {
              if (dynchanGuilds.includes(guild.id)) this.discord.dcguilds.push(guild);
              else if (guild.owner || this.checkPermission(guild.permissions, 0x8))
                this.discord.guilds.push(guild);
            });
          });
          this.loggedIn = true;
        } else {
          this.logout(false);
        }
      });
    } else {
      this.logout(false);
    }
    if (!document.title.includes("Beta")) document.title = `${document.title} ${this.MISC.build}`;
  },
  methods: {
    insertWildcard(wildcard) {
      this.currentConfig[this.showWildcardsInsertIndex] += wildcard;
      this.dialogs.showWildcards = false;
    },
    openDialogShowWildcards(index = null) {
      this.showWildcardsInsertIndex = index;
      this.dialogs.showWildcards = true;
    },
    getChannelNameWildcards() {
      return [
        {
          wildcard: "{username}",
          description: "The username of the user who created the channel.",
          example: "{username}'s channel",
          result: `${this.discord.user.username}'s channel`,
        },
        {
          wildcard: "{displayname}",
          description:
            "The nickname on the current Discord server of the user who created the channel. If no nickname is set, the username is used instead.",
          example: "{displayname}'s channel",
          result: `cool-${this.discord.user.username}'s channel`,
        },
      ];
    },
    openToolTip(title, content) {
      this.dialogs.tooltip = true;
      this.tooltip = {
        title,
        content,
      };
    },
    /* ------------------------ */
    /*   IMPORT/EXPORT CONFIG   */
    /* ------------------------ */
    cancelImport() {
      this.dialogs.importConfig = false;
      this.importConfigFile = null;
      this.importConfigFileData = null;
    },
    checkImportConfig() {
      this.importConfigFileData = null;
      let file = document.getElementById("importConfigFile").files[0];
      if (file) {
        let reader = new FileReader();
        reader.onload = (e) => {
          let data = e.target.result;
          let config = null;
          if (
            file.type == "application/json" ||
            file.type == "text/json" ||
            file.name.endsWith(".json")
          ) {
            try {
              config = JSON.parse(data);
            } catch (err) {
              console.warn("Could not load JSON.");
            }
          } else if (file.name.endsWith(".yaml") || file.name.endsWith(".yml")) {
            try {
              config = YAML.parse(data);
            } catch (err) {
              console.warn("Could not load YAML.");
            }
          }
          if (config != null) {
            this.importConfigFileData = config;
          }
        };
        reader.readAsText(file);
      }
    },
    importConfig() {
      let newConfig = {};
      if (this.importConfigAllData == "1") newConfig = this.copyObject(this.importConfigFileData);
      else
        for (let [key, data] of Object.entries(this.importConfigParts)) {
          if (data.value) newConfig[key] = this.importConfigFileData[key];
        }
      delete newConfig.configid; // Just to make extra sure.
      if (this.importConfigAsNew) {
        this.addConfiguration(newConfig);
      } else {
        this.currentConfig = {
          ...this.copyObject(this.currentConfig),
          ...this.copyObject(newConfig),
        };
        this.dialogs.importConfig = false;
        this.importConfigData = null;
        this.importConfigFile = null;
      }
    },
    openExportConfigDialog() {
      if (this.exportConfigFileName.length == 0) this.exportConfigFileName = "configuration";
      this.dialogs.exportConfig = true;
    },
    validateExportConfigFileName() {
      let name = this.exportConfigFileName;
      name = name.replace(/[^A-Za-z0-9_\-.]/g, "");
      if (this.exportCNFlags.case == "lower") name = `${name.toLowerCase()}`;
      else if (this.exportCNFlags.case == "upper") name = name.toUpperCase();
      this.exportConfigFileName = name;
    },
    addExportFileNamePart(part) {
      const nameParts = {
        dynchanName: `DynChan${this.MISC.build}`,
        userName: this.discord.user.username,
        userID: this.discord.user.id,
        guildName: this.currentGuild.discord.name,
        guildID: this.currentGuild.discord.id,
        configName: this.currentConfig.name,
      };
      let partName = nameParts[part];
      this.exportConfigFileName += partName;
    },
    exportConfig() {
      const contentTypes = {
        json: "application/json",
        yaml: "text/vnd.yaml",
      };
      this.dialogs.exportConfig = false;
      this.validateExportConfigFileName();
      let name = this.exportConfigFileName;
      if (name === "") name = "configuration";
      let contentType = contentTypes[this.exportCNFlags.filetype];
      let exportConfig = this.copyObject(this.currentConfig);
      delete exportConfig.configid;
      exportConfig.guildName = this.currentGuild.discord.name;
      let content = "";
      if (this.exportCNFlags.filetype == "json") content = JSON.stringify(exportConfig, null, 2);
      else content = YAML.stringify(exportConfig);
      let element = document.createElement("a");
      element.setAttribute(
        "href",
        `data:${contentType};charset=utf-8,${encodeURIComponent(content)}`
      );
      element.setAttribute("download", `${name}.${this.exportCNFlags.filetype}`);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    /* ---------- -- ---------- */

    saveAll() {
      if (this.saveTimerInterval) {
        clearInterval(this.saveTimerInterval);
        this.saveTimerInterval = null;
        this.saveTimer = 0;
      }
      this.saveTimerInterval = setInterval(() => {
        this.saveTimer += 10;
        if (this.saveTimer > 200) {
          this.saveTimer = 0;
          clearInterval(this.saveTimerInterval);
          this.saveTimerInterval = null;
        }
      }, 500);
      this.saveBotSettings();
      this.currentGuild.dynchan.botSettingsBackup = this.copyObject(
        this.currentGuild.dynchan.botSettings
      );
      if (this.currentConfig) {
        this.saveConfiguration();
        let filtered = this.currentGuild.dynchan.configurations.filter(
          (c) => c.configid != this.currentConfig.configid
        );
        filtered.push(this.currentConfig);
        this.currentGuild.dynchan.configurations = this.copyObject(filtered);
        this.refreshConfigPickerArray();
      }
      this.dialogs.configurationSaved = true;
    },
    saveBotSettings() {
      axios
        .patch(this.API + "/guilds/", {
          guildid: this.currentGuild.discord.id,
          prefix: this.currentGuild.dynchan.botSettings.prefix,
          enabled: this.currentGuild.dynchan.botSettings.enabled ? "1" : "0",
        })
        .then(() => {})
        .catch((error) => {
          console.warn(error);
        });
    },
    saveConfiguration() {
      axios
        .patch(this.API + "/configurations/", {
          guildid: this.currentGuild.discord.id,
          configid: this.currentConfig.configid,
          new: this.convertConfigBooleans(this.copyObject(this.currentConfig), false),
        })
        .then(() => {})
        .catch((error) => {
          console.warn(error);
        });
    },
    deleteConfiguration() {
      this.dialogs.deleteConfiguration = false;
      if (this.currentConfig)
        axios
          .delete(this.API + "/configurations/", {
            data: {
              guildid: this.currentGuild.discord.id,
              configid: this.currentConfig.configid,
            },
          })
          .then(() => {
            this.currentConfig = null;
            this.selectConfigID = 0;
            this.refreshConfigurations(true);
          })
          .catch((error) => console.warn(error));
    },
    addConfiguration(config = null) {
      let newConfig = {
        name: "New Configuration",
        enabled: "0",
        color: this.randomColor(),
        triggerchannel: "",
        inherit: "1",
        createtext: "1",
        delay: "0",
        isolate: "0",
        powner: "0",
        pother: "0",
        vcategory: "",
        vlock: "0",
        vname: "",
        vuserlimit: "0",
        vbitrate: "64000",
        tcategory: "",
        tlock: "0",
        tname: "",
        tnsfw: "0",
        ttopic: "",
        ...config,
      };
      // newConfig = this.convertConfigBooleans(newConfig, true);
      axios
        .put(this.API + "/configurations/", {
          guildid: this.currentGuild.discord.id,
          new: newConfig,
        })
        .then(() => {
          if (config) {
            this.dialogs.importConfig = false;
            this.importConfigData = null;
            this.importConfigFile = null;
          }
          this.refreshConfigurations(true);
        })
        .catch((error) => console.warn(error));
    },
    checkChanged() {
      if (this.currentGuild) {
        let JSONbs = JSON.stringify(this.currentGuild.dynchan.botSettings);
        let JSONbsb = JSON.stringify(this.currentGuild.dynchan.botSettingsBackup);
        if (JSONbs !== JSONbsb) return true;
        if (this.currentConfig) {
          let currentConfig = this.currentConfig;
          currentConfig.powner = currentConfig.powner.toString();
          currentConfig.pother = currentConfig.pother.toString();
          let JSONcc = JSON.stringify(this.currentConfig);
          let JSONc = JSON.stringify(
            this.currentGuild.dynchan.configurations.find(
              (c) => c.configid == this.currentConfig.configid
            )
          );
          return JSONcc !== JSONc;
        } else return false;
      } else return false;
    },
    resetSettings() {
      this.dialogs.resetSettings = false;
      if (this.currentGuild) {
        this.selectDiscordServer(this.currentGuild.discord.id, false);
        if (this.currentConfig)
          this.currentConfig = this.currentGuild.dynchan.configurations.find(
            (c) => c.configid == this.currentConfig.configid
          );
      }
    },
    genConfigsPickerArray(configs) {
      let pickerArray = [];
      for (let i = 0; i < configs.length; i++) {
        pickerArray.push({
          name: configs[i].name,
          configid: configs[i].configid,
        });
      }
      return pickerArray;
    },
    selectConfiguration(configid) {
      this.currentConfig = this.copyObject(
        this.currentGuild.dynchan.configurations.find((g) => g.configid == configid)
      );
      for (let key in this.PERMISSIONS) {
        this.PERMISSIONS[key].forEach((perm) => {
          ["powner", "pother"].forEach((pType) => {
            if (
              this.checkPermission(this.currentConfig[pType], perm.bit) &&
              !this.activatedPermissions[pType].includes(perm.bit)
            )
              this.activatedPermissions[pType].push(perm.bit);
          });
        });
      }
    },
    refreshConfigurations(selectLast) {
      this.dynchanGetConfigurations(this.currentGuild.discord.id).then((data) => {
        this.currentGuild.dynchan.configurations = data;
        this.refreshConfigPickerArray();
        if (selectLast && this.currentGuild.dynchan.configurations.length > 0) {
          let configID =
            this.currentGuild.dynchan.configurations[
              this.currentGuild.dynchan.configurations.length - 1
            ].configid;
          this.selectConfigID = configID;
        }
      });
    },
    selectDiscordServer(guildid, resetCurrentConfig) {
      this.showView = "webpanel";
      if (window.innerWidth < 900) this.menuVisible = false;
      if (resetCurrentConfig) {
        this.currentConfig = null;
        this.selectConfigID = 0;
      }
      this.requestGuildData(guildid)
        .then((data) => {
          this.currentGuild = {
            dynchan: {
              botSettings: data.botSettings,
              botSettingsBackup: this.copyObject(data.botSettings),
              configurations: data.configurations,
              channels: data.channels,
              // roles: data.roles,
            },
            discord: this.discord.dcguilds.find((g) => g.id == guildid),
            configPickerArray: this.genConfigsPickerArray(data.configurations),
          };
          if (this.currentGuild.dynchan.configurations.length > 0) {
            this.selectConfigID = this.currentGuild.dynchan.configurations[0].configid;
            this.currentConfig = this.copyObject(this.currentGuild.dynchan.configurations[0]);
          }
          this.loading = false;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    async dynchanGetBotSettings(guildid) {
      return new Promise((resolve, reject) => {
        axios
          .post(this.API + "/guilds/", {
            guildid,
          })
          .then((data) => {
            let botSettings = data.data.guild;
            botSettings.enabled = botSettings.enabled == "1";
            resolve(botSettings);
          })
          .catch((error) => {
            console.warn(error);
            reject(error);
          });
      });
    },
    async dynchanGetConfigurations(guildid) {
      return new Promise((resolve, reject) => {
        axios
          .post(this.API + "/configurations/", { guildid })
          .then((data) => {
            let configurations = data.data.items;
            for (let i = 0; i < configurations.length; i++) {
              configurations[i] = this.convertConfigBooleans(configurations[i], true);
            }
            resolve(configurations);
          })
          .catch((error) => {
            console.warn(error);
            reject(error);
          });
      });
    },
    async dynchanGetChannels(guildid) {
      return new Promise((resolve, reject) => {
        axios
          .post(this.API + "/meta/channels/", { guildid })
          .then((data) => {
            let channels = {
              voice: [],
              category: [],
            };
            data.data.items.forEach((channel) => {
              if (!channels[channel.type]) channels[channel.type] = [];
              channels[channel.type].push(channel);
            });
            resolve(channels);
          })
          .catch((error) => {
            console.warn(error);
            reject(error);
          });
      });
    },
    // async dynchanGetRoles(guildid) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post(this.API + "/meta/roles/", { guildid })
    //       .then((data) => {
    //         resolve(data.data.items);
    //       })
    //       .catch((error) => {
    //         console.warn(error);
    //         reject(error);
    //       });
    //   });
    // },
    async requestGuildData(guildid) {
      this.loading = true;
      return new Promise((resolve, reject) => {
        let data = {
          botSettings: null,
          configurations: null,
          channels: null,
          roles: null,
        };
        Promise.all([
          this.dynchanGetBotSettings(guildid),
          this.dynchanGetConfigurations(guildid),
          this.dynchanGetChannels(guildid),
          // this.dynchanGetRoles(guildid),
        ])
          .then((results) => {
            data.botSettings = results[0];
            data.configurations = results[1];
            data.channels = results[2];
            // data.roles = results[3];
            resolve(data);
          })
          .catch((error) => {
            console.warn(error);
            reject(error);
          });
      });
    },
    async dynchanGetGuilds(fullGuilds) {
      let guilds = [];
      fullGuilds.forEach((g) => {
        guilds.push(g.id);
      });
      return new Promise((resolve, reject) => {
        axios
          .post(this.API + "/meta/guilds/", {
            guilds,
          })
          .then((r) => {
            resolve(r.data.items);
          })
          .catch((error) => {
            console.warn(error);
            reject(error);
          });
      });
    },
    refreshConfigPickerArray() {
      this.currentGuild.configPickerArray = this.genConfigsPickerArray(
        this.currentGuild.dynchan.configurations
      );
    },
    copyObject(object) {
      return JSON.parse(JSON.stringify(object));
    },
    checkHex(value) {
      let match = value.match(/#[a-fA-F0-9]{6}/g);
      if (!match) return;
      if (match[0] != value) return;
      this.currentConfig.color = value;
    },
    checkAnimatedIcon() {
      return "png";
      // TODO: Fix trying gif, even though it's png.
      // return features.includes("ANIMATED_ICON") ? "gif" : "png";
    },
    getSavedBuffer() {
      let buffer = this.saveTimer - (this.saveTimer % 20) + 20;
      return buffer;
    },
    randomColor() {
      let hex = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F"];
      let color = "#";
      for (let i = 0; i < 6; i++) color += hex[Math.floor(Math.random() * 16)];
      return color;
    },
    genNumericList(start, end, step) {
      let list = [];
      for (let i = start; i <= end; i += step) list.push(i);
      return list;
    },
    convertConfigBooleans(config, mode = false) {
      let booleans = ["enabled", "inherit", "createtext", "isolate", "vlock", "tlock", "tnsfw"];
      booleans.forEach((key) => {
        if (mode) config[key] = config[key] == "1";
        else config[key] = config[key] ? "1" : "0";
      });

      let permissions = ["powner", "pother"];
      permissions.forEach((key) => {
        if (config[key] !== undefined) {
          if (typeof config[key] === "bigint") {
            config[key] = config[key].toString();
          }
          if (typeof config[key] !== "string") {
            config[key] = config[key].toString();
          }
        }
      });

      return config;
    },
    togglePermission(type, bit, toggleArray = null) {
      if (toggleArray) {
        if (this.activatedPermissions[type].includes(bit))
          this.activatedPermissions[type].splice(this.activatedPermissions[type].indexOf(bit), 1);
        else this.activatedPermissions[type].push(bit);
      }
      this.currentConfig[type] = BigInt(this.currentConfig[type]);
      bit = BigInt(bit);
      this.currentConfig[type] ^= bit;
    },
    checkPermission(perm, bit) {
      perm = BigInt(perm);
      bit = BigInt(bit);
      return (bit & perm) != 0n;
    },
    onResize() {
      if (window.innerWidth < 900) {
        this.menuVisible = false;
        this.useFAB = true;
      } else {
        this.useFAB = false;
      }
    },
    switchTheme() {
      this.theme = this.theme == "default" ? "dark" : "default";
      this.$cookies.set("theme", this.theme);
    },
    async logout(reload = true) {
      if (this.id) await axios.post(this.AUTH.LOGOUT, { id: this.id });
      this.isLoggedIn = false;
      this.notLoggedIn = true;
      this.$cookies.remove("id");
      if (reload) this.$router.go();
    },
    drawerPersistence() {
      if (window.innerWidth < 900) return null;
      else return "full";
    },
  },
};
</script>

<style lang="scss">
@import "~vue-material/dist/theme/engine";
@include md-register-theme(
  "dark",
  (
    theme: dark,
  )
);
@import "~vue-material/dist/theme/all"; // Apply the theme
// :root {  }
*::selection {
  background: #2575ff !important;
}
*::-webkit-scrollbar-track {
  background-color: #e1e1e1;
}
*::-webkit-scrollbar {
  display: none;
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-thumb {
  border-radius: 0 !important;
  // background-color: #757575 !important;
  background-color: #448aff !important;
}

/* ------------------------ */
/*        CUSTOM CSS        */
/* ------------------------ */

:root {
  --background-light: #f1f1f1;
  --background-dark: #616161;
  --button-light: #f1f1f1;
  --button-dark: #424242;
  --action-button-light: #d1d1d1;
  --action-button-dark: #4e4e4e;
  --border-light: #a1a1a1;
  --border-dark: #a1a1a1;
  --hover-light: #a1a1a155;
  --hover-dark: #a1a1a155;
}

// * {
// transition: border-color 0.3s ease, background-color 0.3s ease !important;
// }
#app {
  height: 100vh;
}
.md-app {
  height: 100vh;
}
.md-app-content {
  border-left: none !important;
  border-right: none !important;
}
.md-app-content.expanded {
  padding: 0px !important;
}

#switchTheme {
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.md-avatar {
  border-radius: 10px !important;
  background-color: transparent !important;
}
.discord-avatar {
  margin: 0 !important;
  margin-right: 10px !important;
}

.login {
  background-color: #66bb6a !important;
}
.logout {
  background-color: #ef5350 !important;
}
.loginout {
  position: absolute !important;
  right: 0;
  border-radius: 0 !important;
  height: 100% !important;
}
.statusText {
  // background-color: #f44336 !important;
  display: inline-block;
  width: 50%;
  margin-left: 20px;
}
// #loadingBlanket {
//   position: absolute;
//   left: 0;
//   width: 100%;
//   z-index: 999;
//   background-color: #000000aa;
//   top: 64px;
//   height: calc(100% - 64px);
// }
// @media screen and (max-width: 960px) {
//   #loadingBlanket {
//     top: 48px;
//     height: calc(100% - 48px);
//   }
// }
// @media screen and (max-width: 600px) {
//   #loadingBlanket {
//     top: 56px;
//     height: calc(100% - 56px);
//   }
// }
// #loadingIndicator {
//   position: absolute;
//   z-index: 1000;
//   top: calc(50% - 75px);
//   left: calc(50% - 75px);
// }
.selectedListEntry {
  // background-color: rgba(0, 0, 0, 0.13);
  background-color: #448aff80;
}
.divider-margin {
  margin-top: 10px !important;
}
@media (min-width: 900px) {
  .margin-0 {
    margin: 0 !important;
  }
  .width-30 {
    width: 30% !important;
  }
}
.float-left {
  float: left !important;
}
div.md-menu-content-container.md-theme-default {
  background-color: white !important;
}
div.md-menu-content-container.md-theme-dark {
  background-color: #424242 !important;
}
.md-card.contentCard {
  margin-top: 10px;
  padding-bottom: 10px;
}
span.success {
  color: #43a047;
  font-weight: bold;
}
span.warning {
  color: #ff3b3b;
  font-weight: bold;
}
span.tinyInfoText {
  font-size: 12px;
  margin-left: 8px;
  font-style: italic;
  cursor: pointer;
  color: #3b76db;
  font-weight: bold;
}
span.tinyInfoText:hover {
  color: #448aff;
}
.md-table-cell:not(.longText) .md-table-cell-container {
  width: 200px !important;
}
@media (max-width: 1300px) {
  .md-table-cell.longText .md-table-cell-container {
    width: 400px !important;
  }
}
.md-table-cell:nth-child(1) .md-table-cell-container {
  width: 45px !important;
  padding: 5px 0 !important;
}
div#exportChips {
  padding: 4px 0;
}
.md-chip.exportChips {
  margin: 3px;
}
@media (min-width: 550px) {
  #importConfig .md-dialog-container {
    min-width: 500px;
  }
}
.md-content#importConfigPartSwitchContainer {
  overflow: auto;
  max-height: 300px;
}
span#importConfigSelectDataLabel {
  margin-left: 10px;
}
div.vue-slider-dot-tooltip {
  z-index: 1000;
}

/* ------------------------ */
/*        CATEGORIZED       */
/* ------------------------ */

/* -------- Drawer -------- */

@media (min-width: 500px) and (max-width: 700px) {
  .md-drawer {
    width: 500px !important;
  }
}
#drawerToolbar {
  position: sticky !important;
  z-index: 1000 !important;
  left: 0 !important;
  top: 0 !important;
}

/* --------- Cards -------- */

.md-card {
  border-radius: 20px !important;
  padding: 5px !important;
  box-shadow: none !important;
}
.md-card.md-theme-default {
  background-color: var(--background-light) !important;
}
.md-card.md-theme-dark {
  background-color: var(--background-dark) !important;
}
.md-card-actions#botSettings {
  display: unset !important;
}
.md-card.contentCard:not(#permissionsCard):not(#imprintCard) .md-card-content {
  padding: 0 5px 0 5px !important;
}
.md-card:not(:last-of-type) {
  margin-bottom: 20px !important;
}
@media (max-width: 899px) {
  .md-card.contentCard {
    margin-left: -10px !important;
    margin-right: -10px !important;
    box-shadow: none;
    border: none;
    border-radius: 0px;
  }
}
@media (min-width: 1300px) {
  .md-card.fullWidth {
    width: calc(100% - 32px);
  }
  .md-card {
    width: calc(50% - 32px);
    min-height: 585px;
    float: left;
  }
}
.md-card-actions {
  padding-left: 30px !important;
  padding-right: 30px !important;
  padding-bottom: 25px !important;
}

/* -------- Buttons ------- */

button.mdc-primary:not(:disabled) *,
button.mdc-accent:not(:disabled) *,
button.mdc-success:not(:disabled) *,
button.mdc-warning:not(:disabled) *,
button.mdc-export:not(:disabled) *,
button.mdc-import:not(:disabled) * {
  color: white !important;
}
.md-button.mdc-primary *,
button.mdc-primary:not(:disabled) {
  background-color: #4287ff;
}
button.mdc-accent:not(:disabled) {
  background-color: #ff5252 !important;
}
button.mdc-success:not(:disabled) {
  background-color: #43a047 !important;
}
button.mdc-warning:not(:disabled) {
  background-color: #db9422 !important;
}
button.mdc-export:not(:disabled) {
  background-color: #e91e63 !important;
}
button.mdc-import:not(:disabled) {
  background-color: #8e24aa !important;
}
.md-button.mainButtons {
  border-radius: 20px !important;
  margin: 2.5px !important;
  width: calc(50% - 40px);
  float: left;
  height: 56px;
  margin-left: 20px !important;
  margin-right: 20px !important;
}
@media (max-width: 500px) {
  .md-button.mainButtons {
    width: 100%;
    margin: 2.5px 0 !important;
  }
}
.md-button.mainButtons::before {
  border-radius: 0px !important;
}
.md-button:not(.md-raised):not(#menu-button).md-theme-default {
  background-color: var(--action-button-light);
}
.md-button:not(.md-raised):not(#menu-button).md-theme-dark {
  background-color: var(--action-button-dark);
}

/* -------- Dialogs ------- */

.md-dialog.mdc-primary .md-dialog-container {
  border: 2px solid #448aff;
}
.md-dialog.mdc-accent .md-dialog-container {
  border: 2px solid #ff5252;
}
.md-dialog.mdc-success .md-dialog-container {
  border: 2px solid #43a047;
}
.md-dialog.mdc-warning .md-dialog-container {
  border: 2px solid #db9422;
}
.md-dialog.mdc-export .md-dialog-container {
  border: 2px solid #e91e63;
}
.md-dialog.mdc-import .md-dialog-container {
  border: 2px solid #8e24aa;
}

/* ------- Tooltips ------- */

.md-button.tooltip,
.md-button.inputActionButton {
  margin-top: -12px;
  background-color: transparent !important;
}
.md-button.inputActionButton .md-ripple .md-button-content i {
  background-color: transparent !important;
}
.md-button.inputActionButton {
  margin-right: -5px;
}

.md-button.sliderTooltip {
  position: absolute;
  right: 0;
  margin-top: unset;
  top: 8px;
}

/* -------- Switch -------- */

.md-switch .md-switch-thumb {
  display: none;
}
.md-switch {
  width: calc(100% - 20px) !important;
  border-radius: 15px;
  height: 50px;
  margin: 5px 10px 5px 10px !important;
  border: 2px solid var(--border-light);
  background-color: var(--button-light);
}
.md-switch.md-theme-dark {
  border-color: var(--border-dark);
  background-color: var(--button-dark);
}
.md-switch:hover {
  background-color: #00000034 !important;
}
.md-switch.warning:hover {
  background-color: #f4433644 !important;
}
.md-switch.md-checked {
  border-color: #2196f3 !important;
}
.md-switch.md-checked:hover {
  background-color: #4489ff2a !important;
}
.md-switch .md-switch-container {
  margin: 0 !important;
  border-radius: 10px 0 0 8px !important;
  height: 100% !important;
  box-shadow: none !important;
  background-color: transparent !important;
  border-right: 2px solid var(--border-light);
}
.md-switch.md-theme-dark .md-switch-container {
  border-color: var(--border-dark);
}
.md-switch.md-checked .md-switch-container {
  background-color: #2196f3 !important;
  border-color: #2196f3 !important;
}
.md-switch.warning .md-switch-container {
  border-color: #f44336;
  // background-color: #f44336 !important;
}
.md-switch.warning {
  border-color: #f44336;
}
.md-switch.md-disabled .md-switch-container {
  background-color: transparent !important;
  border-color: #5c5c5c83 !important;
}
.md-switch.md-disabled {
  color: #7e7e7e !important;
  border-color: #5c5c5c83 !important;
  background-color: #3131313a !important;
}
// .md-switch.md-disabled:hover {
//   background-color: #53535354 !important;
// }
div.switchContainer:not(.permissionSwitch) button.md-button.switchTooltip {
  right: 0;
}
div.switchContainer.permissionSwitch button.md-button.switchTooltip {
  margin-top: 15px;
  margin-left: -50px;
}
div.switchContainer:not(.permissionSwitch) {
  width: auto;
}
.md-switch.permissionSwitch {
  margin: 10px 5px 10px 5px !important;
  width: calc(100% - 10px) !important;
}
div.switchContainer.permissionSwitch {
  width: calc(100% / 4 - 10px) !important;
  float: left;
}
@media (max-width: 2100px) {
  div.switchContainer.permissionSwitch {
    width: calc(100% / 3 - 10px) !important;
  }
}
@media (max-width: 1700px) {
  div.switchContainer.permissionSwitch {
    width: calc(100% / 2 - 10px) !important;
  }
}
@media (max-width: 1400px) {
  div.switchContainer.permissionSwitch {
    width: calc(100% - 10px) !important;
  }
}

div.switchContainer button.md-button.switchTooltip {
  position: absolute;
  margin-top: 10px;
  margin-right: 18px;
}
.md-switch-label {
  line-height: 48px !important;
  font-size: 17px;
  width: 100%;
  height: 100% !important;
}
.md-switch .md-ripple {
  display: none;
}
@media (min-width: 1200px) {
  .md-switch.importConfigPartSwitch {
    width: calc(50% - 10px) !important;
    margin: 0 5px !important;
  }
}
@media (min-width: 2400px) {
  .md-switch.importConfigPartSwitch {
    width: calc(25% - 10px) !important;
    margin: 0 5px !important;
  }
}

/* ------- List Item ------ */

.md-list:not(#drawer) {
  background-color: var(--background-light);
}
.md-list:not(#drawer).md-theme-dark {
  background-color: var(--background-dark);
}

.md-list-item {
  border-radius: 0px !important;
}
.md-list-item-button,
.md-list-item-link {
  border-radius: 0px !important;
}

/* -------- Fields -------- */

.md-field {
  border-radius: 15px !important;
  padding-left: 10px;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  background-color: var(--button-light);
  border: 2px solid var(--border-light);
  width: calc(100% - 20px) !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.md-field.md-theme-dark {
  background-color: var(--button-dark);
  border: 2px solid var(--border-dark);
}

.md-field label {
  margin-top: 5px;
  margin-left: 10px;
}
.md-field::before,
.md-field::after {
  content: none !important;
}
.md-field.slider {
  padding: 50px 20px 35px 20px;
}
.md-field#exportConfigFileName {
  padding-right: 10px;
}
.md-field.warning {
  border-color: #f44336 !important;
}
.md-field label {
  top: 0 !important;
  font-size: 13px !important;
  font-weight: bold;
}

/* ------ Select Menu ----- */

.md-select-menu,
.md-menu-content-container {
  border-radius: 10px !important;
}
.md-select#permissionsForSelect {
  margin-bottom: 10px !important;
}

/* -------- Dialog -------- */

.md-dialog-container {
  border-radius: 10px !important;
}

/* ------ Imprint Font Colors ----- */

.md-card#imprintCard.md-theme-dark .md-display-1 {
  color: #ffffff;
}

/* ------- Tooltips ------- */

.md-tooltip {
  background-color: #000000ba !important;
  color: #fefefe !important;
  font-size: 14px !important;
  padding: 6px 8px !important;
  height: 32px !important;
  line-height: 20px !important;
  border-radius: 5px !important;
}

/* --------- Tabs --------- */

.md-tabs-navigation .md-button::before,
.md-tabs-navigation .md-button {
  max-width: unset !important;
  border-radius: 0 !important;
}
.md-tabs-navigation .md-button .md-ripple {
  border-radius: 0;
}
.md-tabs-navigation .md-button:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.md-tabs-navigation .md-button:nth-child(3) {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.md-tabs-navigation .md-button.md-active {
  border-bottom: 2px solid #448aff;
}
.md-tabs-indicator {
  display: none;
}
.md-tab {
  padding: 16px 0 !important;
}
</style>
